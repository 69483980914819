<template>
  <v-app>
    <v-main class="black lighten-1">
      <navigation-header />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavigationHeader from '@/components/NavigationHeader';

export default {
  name: 'App',
  components: {
    NavigationHeader,
  },
};
</script>
