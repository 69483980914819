import Home from '@/components/Home';
import PrivacyPolicy from '@/components/PrivacyPolicy';
import ServiceTerms from '@/components/ServiceTerms';

export default [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/service-terms',
    component: ServiceTerms,
  },
];
