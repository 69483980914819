<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <h1 class="text-uppercase blue--text">
          tolastarras
          <span class="grey--text">.</span>
          <span class="pl-6 amber--text">com</span>
        </h1>
        <div class="d-flex py-0">
          <hr class="primary">
          <!-- <hr class="amber"> -->
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <div class="logo-container">
          <v-img
            max-width="300"
            class="logo pt-10"
            src="@/assets/tolas-logo.png"
            alt="tolastarras"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Raleway:200,100,400');

.container {
  font-family: 'Raleway', sans-serif;
  margin: 0 auto;

  h1 {
    letter-spacing: 10px;
    font-size: 3.8rem;

    > span {
      margin-left: -1.4rem;
    }
  }

  hr {
    width: 7rem;
    border-radius: 10px;
    height: 3px;
    margin: 3rem auto;
  }

  .logo-container {
    padding-top: 3rem;

    .logo {
      width: 15rem;
      height: 15rem;
    }
  }
}

@media screen and (max-width: 640px) {
  h1 {
    letter-spacing: 5px !important;
    font-size: 2rem !important;

    > span {
      margin-left: -1rem !important;
    }
  }

  hr {
    width: 4rem !important;
    margin: 1rem auto !important;
  }

  .logo {
    width: 8rem !important;
    height: 8rem !important;
  }
}

@media screen and (max-width: 360px) {
  h1 {
    font-size: 1.4rem !important;

    > span {
      margin-left: -0.6rem !important;
    }
  }
}
</style>
