<template>
  <v-container>
    <div class="white--text">
      <h1 class="text-center text-h3 mb-10">Terms of Service</h1>
      These Terms of Service govern your use of this website. By accessing this website,
      you agree to be bound by these Terms of Service and all applicable laws and regulations.
      If you do not agree with any of these terms, you are prohibited from using or accessing
      this site. The materials contained in this website are protected by applicable copyright
      and trademark law.
      <br /><br />
      <p class="font-weight-bold">Use License</p>
      Permission is granted to temporarily download one copy of the materials (information or
      software) on Tolastarra's website for personal, non-commercial transitory viewing only.
      This is the grant of a license, not a transfer of title, and under this license, you may not:
      <br /><br />
      <p class="font-weight-bold">Modify or copy the materials:</p>
      Use the materials for any commercial purpose or for any public display (commercial or
      non-commercial); Attempt to decompile or reverse engineer any software contained on
      Tolastarra's website; Remove any copyright or other proprietary notations from the
      materials; or Transfer the materials to another person or "mirror" the materials on
      any other server.
      <br /><br />
      <p class="font-weight-bold">Disclaimer</p>
      The materials on Tolastarra's website are provided on an 'as is' basis. Tolastarras makes
      no warranties, expressed or implied, and hereby disclaims and negates all other warranties
      including, without limitation, implied warranties or conditions of merchantability, fitness
      for a particular purpose, or non-infringement of intellectual property or other violation
      of rights.
      <br /><br />
      <p class="font-weight-bold">Limitations</p>
      In no event shall Tolastarra or its suppliers be liable for any damages (including, without
      limitation, damages for loss of data or profit, or due to business interruption) arising out
      of the use or inability to use the materials on Tolastarra's website, even if Tolastarra or
      a Tolastarra authorized representative has been notified orally or in writing of the
      possibility of such damage.
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ServiceTerms',
};
</script>
