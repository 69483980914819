<template>
  <nav class="white--text">
    <v-toolbar
      color='black'
      flat
    >
      <v-spacer></v-spacer>
      <v-btn text>
        <router-link to="/">Home</router-link>
      </v-btn>
      <v-btn text>
        <router-link to="/privacy-policy">Privacy Policy</router-link>
      </v-btn>
      <v-btn text>
        <router-link to="/service-terms">Terms of Service</router-link>
      </v-btn>
    </v-toolbar>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
};
</script>
