import Vue from 'vue';
import VueAnalytics from 'vue-ua';
import VueRouter from 'vue-router';
import App from './App';
import vuetify from './plugins/vuetify';
import Routes from './routes';

Vue.config.productionTip = false;

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: Routes,
});

Vue.use(VueAnalytics, {
  appName: 'tolastarras',
  appVersion: '1',
  trackingId: 'UA-131115781-1',
});

new Vue({
  vuetify,
  render: (h) => h(App),
  router,
}).$mount('#app');
