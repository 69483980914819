<template>
  <v-container>
    <div class="white--text">
      <h1 class="text-center text-h3 mb-10">Privacy Policy</h1>
      At tolastarras.com, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, maintain, and disclose information obtained from users ("Users") of our website [https://tolastarras.com] ("Site") and any products or services offered by us.
      <br /><br />
      <p class="font-weight-bold">Personal Identification Information:</p>
      We don't collect any personal identification information from users.
      <br /><br />
      <p class="font-weight-bold">Non-Personal Identification Information:</p>
      We don't collect any non-personal identification information about Users.
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>
